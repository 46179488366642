export default {
    install: (app, options) => {
        app.config.globalProperties.__ = (key, replacements) => {
            return Lang.get(`strings.${key}`, replacements);
        };

        app.config.globalProperties.trans = (key, replacements) => {
            return Lang.get(key, replacements);
        };
    },
}
